import React from 'react';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


const resizeWEBP = (src, option) => {
    // console.log(option)
    const path = `${src.replace('//a.storyblok.com/', `//img2.storyblok.com/${option}x0/`)} ${option}w`

    // const path = `${src.replace('//a.storyblok.com/', `//img2.storyblok.com/${option}x0/filters:format(webp)/`)} ${option}w`
    return path
}

const ImageTag = ({ src }) => {

    const sizes = [
        `${resizeWEBP(src, '640')}`,
        // `${resize(src, '640')}`,
        `${resizeWEBP(src, '768')}`,
        // `${resize(src, '768')}`,
        `${resizeWEBP(src, '1024')} `,
        // `${resize(src, '1024')}`,
        `${resizeWEBP(src, '1366')}`,
        // `${resize(src, '1366')}`,
        `${resizeWEBP(src, '1600')}`,
        // `${resize(src, '1600')}`,
        `${resizeWEBP(src, '1920')}`,
        // `${resize(src, '1920')}`,
        `${resizeWEBP(src, '3672')}`,
        // `${resize(src, '3672')}`,
    ]
    // console.log(sizes)

    return(
        <img
        data-sizes="auto"
        // data-src={src}
        data-src={resizeWEBP(src, '32')}
        data-srcset={sizes}
        className="lazyload" 
        />
    )
}


const Image = ({
    src,
    cover
}) => {

    // console.log(src)
    if(!src || typeof src !== 'string') return <></>

    const pathFragments = src.split('/')

    // console.log("size", pathFragments[6])
    if(!pathFragments[5]) return <></>

    const width = parseInt(pathFragments[5].split('x')[0])
    const height = parseInt(pathFragments[5].split('x')[1])
    // const ratio = width/height
    const ratio = height/width

    // const padding = `${1/ratio*100}%`

    return(
        <div>
            {
               typeof cover === 'undefined' || !cover ?
                <ImageTag src={src} />
                :
                <div style={{ position: 'relative', height: `${450}px`, width: `${450/ratio}px`}}>
                    <ImageTag src={src} />
                </div>
            }
        </div>
    )
}

export default Image